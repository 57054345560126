<template>
    <div class="dashboard">
        <dashboard-header v-if="!itsAnIframe" :open="openSidebar" @toggleSidebar="toggleSidebar" @logout="logout" />
        <dashboard-sidebar v-if="!itsAnIframe" :open="openSidebar" @toggleSidebar="toggleSidebar" />
        <div
            class="dashboard__content"
            :class="{
                'dashboard__content--open': openSidebar,
                'dashboard__content--its-an-iframe': itsAnIframe
            }"
        >
            <transition name="fadeIn" enter-active-class="animated fadeIn">
                <router-view />
            </transition>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

import DashboardHeader from '@/components/layouts/dashboard/Header';
import DashboardSidebar from '@/components/layouts/dashboard/Sidebar.vue';

import iframeMixin from '@/plugins/mixin/iframe.mixin';

export default {
    name: 'dashboard',
    components: { DashboardHeader, DashboardSidebar },
    mixins: [iframeMixin],
    data() {
        return {
            openSidebar: true,
            windowWidth: 0,
            device: '',
        }
    },
    watch: {
        device(newVal, oldVal) {
            let index = newVal.indexOf(':');
            let status = newVal.substring(index + 1);
            if(status === 'true') {
                this.$cookies.remove('_atdv');
                this.logout();
            }
        },
    },
    created() {
        setInterval(() => {
            if(!this.$cookies.get('_atdv')) return;
            this.device = this.$cookies.get('_atdv');
        }, 1000);
    },
    async beforeMount() {
        const reminder_tfa = await this.is2FARequired();

        if(reminder_tfa && this.$cookies.get('_atid') && !this.$cookies.get('_atfa')) {
            this.logout();
            return;
        }
        if (!this.$cookies.get('_atid')) {
            this.logout()
            return;
        }

        this.getAccount();
    },
    methods: {
        ...mapActions({ setAccount: 'accounts/setAccount' }),
        toggleSidebar() {
            this.openSidebar = !this.openSidebar;
        },
        getWindowWidth() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 992) { this.openSidebar = false; } 
            else { this.openSidebar = true; }
        },
        logout() {
            this.$router.push({ path: '/?logout=1' });
        },
        async getAccount() {
            try {
                const  { data }  = await this.axiosAccount.get('/api/accounts/me');
                const { email_verified, phone_verified } = data;

                if (!email_verified) {
                    this.$toasted.global.errorMessage(this.$t('You need to verify your email to complete registration'));
                    this.logout();
                    return;
                }

                if (!phone_verified) {
                    this.$toasted.global.errorMessage(this.$t('You need to verify your phone number to complete the registration'));
                    this.logout();
                    return;
                }
                
                this.setAccount(data);
            } catch (error) {
                this.showError(error);

                if (error.response.status === 403) {
                    this.logout();
                }
            }
        },
        async is2FARequired() {
            const { data } = await this.axiosAccount.get('/api/two-factor/reminder');
            return data.reminder;
        },
    },
    mounted() {
        window.addEventListener("resize", this.getWindowWidth)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.getWindowWidth)
    }
};
</script>

<style lang="scss">
.dashboard {
    width: 100%;
    font-family: 'Inter', sans-serif;

    &__content {
        float: right;
        margin-top: 90px;
        min-height: calc(100vh - 90px);
        width: calc(100% - 70px);
        background: #edf2f9;
        box-sizing: border-box;
        transition: 500ms all;
        padding: 30px;

        @media (max-width: 768px) {
            width: 100%;
            padding: 20px 15px;
        }

        &--open {
            width: calc(100% - 250px);

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        &--its-an-iframe {
            margin-top: 0;
            min-height: 100vh;
            width: 100%;
        }
    }
}
</style>
<template>
    <div class="header" :class="{ 'header--open': open }">
        <b-navbar type="light" class="header__navbar h-100">
            <div class="header__toggler d-md-none">
                <button type="button" aria-label="Toggle navigation" class="header__toggler-btn"
                    aria-controls="nav-collapse" @click="$emit('toggleSidebar')">
                    <i class="fas fa-bars"></i>
                </button>
            </div>
            <h3 class="header__title">{{ $t(this.$route.meta.title) }}</h3>
            <div class="ml-auto d-flex header__controls">
                <div class="d-none">
                    <button class="header__controls-btn">
                        <img src="@/assets/images/dashboard/icons/question-circle.svg" alt="help">
                    </button>
                    <button class="header__controls-btn">
                        <img src="@/assets/images/dashboard/icons/bell.svg" alt="notifications">
                    </button>
                    <button class="header__controls-btn">
                        <img src="@/assets/images/dashboard/icons/apps.svg" alt="apps">
                    </button>
                </div>
                <div>
                    <languages variant="link" toggle-class="" />
                </div>
                <b-dropdown v-if="account" right variant="link" toggle-class="text-decoration-none p-0" no-caret>
                    <template #button-content>
                        <div class="d-block d-md-none">
                            <Avatar :name="account.first_name" :lastName="account.last_name" :size="'xxs'"
                                :account="account" />
                        </div>
                        <div class="header__avatar-container d-none d-md-flex">
                            <Avatar :name="account.first_name" :lastName="account.last_name" :size="'xxs'"
                                :account="account" />
                            <div class="text-left">
                                <h5>{{ account.first_name }} {{ account.last_name }}</h5>
                                <span>Envia.com</span>
                            </div>
                            <img src="@/assets/images/dashboard/icons/check.svg" alt="status">
                        </div>
                    </template>
                    <b-dropdown-item active-class="" :to="{ path: '/dashboard/profile' }">{{
                        $t('Profile') }}</b-dropdown-item>
                    <b-dropdown-item active-class="" @click="$emit('logout')">{{ $t('Logout') }}</b-dropdown-item>
                </b-dropdown>
            </div>
        </b-navbar>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Avatar from '@/components/ui/Avatar.vue'
import Languages from "@/components/Languages";

export default {
    name: 'Header',
    props: {
        open: {
            type: Boolean,
            default: false,
        }
    },
    components: { Avatar, Languages },
    computed: {
        ...mapState('accounts', ['account']),
    },
};
</script>
<style lang="scss" scoped>
.header {
    background-color: #edf2f9;
    box-shadow: 0 0 15px rgba(#000000, 0.03);
    height: 90px;
    position: fixed;
    right: 0;
    top: 0;
    width: calc(100% - 70px);
    transition: 500ms all;
    z-index: 1030;
    border-bottom: 1px solid #D9D9D9;

    @media(max-width: 768px) {
        width: 100%;
    }

    &--open {
        width: calc(100% - 250px);

        @media(max-width: 768px) {
            width: 100%;
        }
    }

    &__navbar {
        display: flex;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;

        @media(max-width: 992px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__title {
        color: $accounts-secondary;
        font-size: 1.3rem;
        font-weight: 700;
        margin-bottom: 0;

        @media(max-width: 992px) {
            display: none;
        }
    }

    &__brand {
        align-items: center;
        display: flex;
        justify-content: center;
        width: calc(250px - 1rem);

        @media(max-width: 992px) {
            width: auto;
        }
    }

    &__search-group {
        background: #F6F7F9;
        border-radius: 20px;
        height: 50px;
        width: 350px;
    }

    &__search-prepend {
        align-items: center;
        background-color: transparent;
        border-radius: 20px 0px 0px 20px;
        display: flex;
        justify-content: center;
        padding-left: 20px;
    }

    &__search-input {
        background-color: transparent;
        border: none;
        border-radius: 0px 20px 20px 0px !important;
        height: 100% !important;

        &:focus {
            background-color: transparent;
            box-shadow: none;
        }
    }

    &__notification-button {
        align-items: center;
        background-color: #F6F7F9 !important;
        border: none;
        border-radius: 100%;
        display: flex;
        height: 50px;
        justify-content: center;
        margin-right: 15px;
        position: relative;
        width: 50px;

        &:hover {
            background-color: #eeeeee !important;
        }
    }

    &__counter {
        background-color: #FF2F2F;
        border-radius: 100%;
        font-size: 10px;
        font-weight: 500;
        color: white;
        height: 15px;
        position: absolute;
        right: -4px;
        top: -3px;
        width: 15px;
    }

    &__toggler {
        margin-right: 20px;
    }

    &__toggler-btn {
        border: none;
        border-radius: 50%;
        background: none;
        color: #333875;
        width: 2rem;
        height: 2rem;
        font-size: 1.4rem;
    }

    &__controls {
        display: flex;
        align-items: center;
        row-gap: 5px;
    }

    &__controls-btn {
        background-color: #ffffff00;
        border: none;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        box-sizing: border-box;

        img {
            width: 1.6rem;
        }
    }

    &__avatar-container {
        background-color: #fff;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 5px;

        h5 {
            font-size: 0.8rem;
            margin-bottom: 0;
        }

        span {
            font-size: 0.7rem;
            color: #a3a5a7;
        }
    }

    &__avatar-img-container {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        border: 1px solid #000;
        overflow: hidden;
    }

    &__avatar-img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center;
    }
}
</style>

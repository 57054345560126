<template>
    <div class="sidebar">
        <div class="sidebar__content" :class="{ 'sidebar__content--open': open }">
            <div class="sidebar__links-container">
                <div class="sidebar__brand">
                    <img src="@/assets/images/logos/accounts-logo.svg" class="sidebar__brand-logo img-fluid"
                        :class="{ 'sidebar__brand-logo img-fluid d-none': !open }" alt="Tendencys" />
                    <svg :class="{ 'd-none': open }" width="31" height="23" viewBox="0 0 31 23" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M19.3205 7.19928L20.7028 22.0344C20.7206 22.2297 20.8123 22.4113 20.9596 22.5433C21.107 22.6753 21.2993 22.7481 21.4986 22.7474H29.7421C29.8413 22.7472 29.9394 22.7267 30.0302 22.6872C30.121 22.6478 30.2024 22.5902 30.2694 22.5181C30.3363 22.446 30.3873 22.3611 30.4191 22.2686C30.4509 22.1761 30.4629 22.0781 30.4542 21.9809L28.7787 4.01796V4.03857C28.7326 3.49873 28.8331 2.93829 27.522 2.46027C24.2046 1.23637 19.2325 6.74186 19.3205 7.19928Z"
                            fill="#301B92" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1.21612 22.7309H9.81979C10.198 22.7298 10.5711 22.6447 10.9112 22.4821C11.2514 22.3194 11.5498 22.0833 11.7843 21.7913L26.2857 3.51523C26.613 3.14353 27.0539 2.88616 27.5424 2.78172C27.9612 2.7199 27.8733 2.68694 28.2921 2.85177L26.2397 1.42595C25.1488 0.692241 23.8963 0.22406 22.5854 0.0599893C21.2745 -0.104082 19.9428 0.0406532 18.6999 0.482267C17.6444 0.840783 16.6516 1.33941 16.1867 1.9699L0.650636 21.5977C0.569512 21.7018 0.519564 21.8262 0.506417 21.9568C0.493271 22.0874 0.517449 22.219 0.576228 22.3368C0.635007 22.4546 0.726051 22.5539 0.839106 22.6235C0.95216 22.6931 1.08273 22.7303 1.21612 22.7309Z"
                            fill="#12B4BA" />
                    </svg>
                </div>
                <b-nav class="sidebar__nav" :class="{ 'sidebar__nav--open': open }" vertical>
                    <b-nav-item class="sidebar__nav-item" :class="{ 'sidebar__nav-item--open': !open }"
                        link-classes="sidebar__nav-link" exact :to="{ path: '/dashboard' }" @click="closeSideBarMobile">
                        <i class="fas fa-home"></i>
                        <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                            {{ $t('Home') }}
                        </span>
                    </b-nav-item>
                    <b-nav-item-dropdown
                        id="my-nav-dropdown"
                        class="sidebar__nav-item"
                        toggle-class="sidebar__nav-link"
                        menu-class="sidebar__dropdown-menu"
                        no-caret
                    >
                        <template #button-content>
                            <i class="fas fa-building"></i>
                            <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                                {{ $t('Organizations') }}
                            </span>
                            <i class="ml-auto fas fa-caret-down"></i>
                        </template>
                        <b-dropdown-item link-class="sidebar__dropdown-menu-item" exact :to="{ path: '/profile/companies' }" @click="closeSideBarMobile">
                            <i class="far fa-id-card" v-show="!open"></i>
                            <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                                {{ $t('My organizations') }}
                            </span>
                        </b-dropdown-item>
                        <b-dropdown-item link-class="sidebar__dropdown-menu-item" exact :to="{ path: '/profile/scopes' }" @click="closeSideBarMobile">
                            <i class="fas fa-user-shield" v-show="!open"></i>
                            <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                                {{ $t('Scopes') }}
                            </span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown
                        id="my-nav-dropdown"
                        class="sidebar__nav-item"
                        toggle-class="sidebar__nav-link"
                        menu-class="sidebar__dropdown-menu"
                        no-caret
                    >
                        <template #button-content>
                            <i class="fas fa-user"></i>
                            <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                                {{ $t('Profile') }}
                            </span>
                            <i class="ml-auto fas fa-caret-down"></i>
                        </template>
                        <b-dropdown-item link-class="sidebar__dropdown-menu-item" exact :to="{ path: '/dashboard/profile' }" @click="closeSideBarMobile">
                            <i class="far fa-id-card" v-show="!open"></i>
                            <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                                {{ $t('My Profile') }}
                            </span>
                        </b-dropdown-item>
                        <b-dropdown-item link-class="sidebar__dropdown-menu-item" exact :to="{ path: '/dashboard/profile/security' }" @click="closeSideBarMobile">
                            <i class="fas fa-user-shield" v-show="!open"></i>
                            <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                                {{ $t('Security') }}
                            </span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-nav>
            </div>
            <div class="sidebar__configs-container">
                <b-nav class="sidebar__nav" :class="{ 'sidebar__nav--open': open }" vertical>
                    <b-nav-item class="sidebar__nav-item" :class="{ 'sidebar__nav-item--open': !open }"
                        link-classes="sidebar__nav-link" exact :to="{ path: '/?logout=1' }">
                        <i class="fas fa-sign-out-alt"></i>
                        <span class="sidebar__nav-text" :class="{ 'sidebar__nav-text--open': open }">
                            {{ $t('Logout') }}
                        </span>
                    </b-nav-item>
                </b-nav>
                <button class="sidebar__button-collapse" :class="{ 'sidebar__button-collapse--open': open }"
                    @click="$emit('toggleSidebar')">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.98614 7.20303L16.3611 0.828027C16.8018 0.387402 17.5143 0.387402 17.9502 0.828027L19.0096 1.8874C19.4502 2.32803 19.4502 3.04053 19.0096 3.47646L14.4955 7.9999L19.0143 12.5187C19.4549 12.9593 19.4549 13.6718 19.0143 14.1077L17.9549 15.1718C17.5143 15.6124 16.8018 15.6124 16.3658 15.1718L9.99082 8.79678C9.54551 8.35615 9.54551 7.64365 9.98614 7.20303ZM0.986135 8.79678L7.36114 15.1718C7.80176 15.6124 8.51426 15.6124 8.9502 15.1718L10.0096 14.1124C10.4502 13.6718 10.4502 12.9593 10.0096 12.5233L5.49551 7.9999L10.0143 3.48115C10.4549 3.04053 10.4549 2.32803 10.0143 1.89209L8.95489 0.828027C8.51426 0.387402 7.80176 0.387402 7.36582 0.828027L0.990823 7.20303C0.54551 7.64365 0.54551 8.35615 0.986135 8.79678Z"
                            fill="#FC7E7E" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="sidebar__backdrop d-md-none " :class="{'sidebar__backdrop--open' : open}" @click="$emit('toggleSidebar')"></div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    props: {
        open: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        isProduction() {
            return process.env.NODE_ENV === 'production';
        },
    },
    methods: {
        closeSideBarMobile() {
            if(window.innerWidth < 992 && this.open) {
                this.$emit('toggleSidebar');
            }
        }
    }
};
</script>

<style lang="scss">
.sidebar {
    position: relative;

    &__content {
        background-color: #ffffff;
        box-shadow: 0 0 12px rgba(#000000, 0.06);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        transition: 500ms all;
        width: 100%;
        max-width: 70px;
        z-index: 1031;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 768px) {
            height: 100vh;
            left: -250px;
            top: 0;
            z-index: 1031;
        }

        &--open {
            max-width: 250px;
            
            @media (max-width: 992px) {
                left: 0px;
            }
        }
    }

    &__links-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
    }

    &__configs-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 16px 0;
        border-top: solid 2px #C4C4C4;
        position: relative;
    }

    &__brand {
        align-items: center;
        display: flex;
        height: 90px;
        justify-content: flex-start;
    }

    &__brand-logo {
        width: 150px;
    }

    &__nav {
        width: 100%;
    }

    &__nav-item {
        align-items: center;
        display: flex;
        list-style: none;
        flex-direction: column;


        &--open {
            a {
                justify-content: center;
            }
        }
    }

    &__nav-link {
        color: $general-black;
        display: flex !important;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        padding: 16px !important;
        transition: 100ms all;
        width: 100%;
        overflow: hidden;

        &.active {
            background: #d0f0f1;
            border-right: #12b4ba 4px solid;

            i {
                color: #12b4ba;
            }
        }

        &:hover {
            color: $general-black;
            background: #babed6;
        }
    }

    &__nav-icon {
        margin-top: -3px;
    }

    &__nav-text {
        display: none;
        font-size: 0;
        line-height: 1;
        margin-left: 10px;
        max-width: 0;
        opacity: 0;
        transition: 500ms all;
        transition-delay: 250ms;
        transition-property: opacity;

        &--open {
            max-width: 100%;
            opacity: 1;
            font-size: 14px;
            display: block;
        }
    }

    &__dropdown-menu {
        margin: 0 !important;
        border: none !important;
        padding: 0 !important;
        position: relative !important;
        transform: none !important;
        width: 100%;
    }

    &__dropdown-menu-item {
        color: $general-black !important;
        font-size: 14px;
        font-weight: 500 !important;
        line-height: 1;
        padding: 14px 16px 14px 24px !important;
        position: relative !important;

        &:active {
            background-color:#12b4ba !important;
            color: #ffffff !important;
        }

        &.active {
            background: #d0f0f1 !important;
            border-right: #12b4ba 4px solid !important;

            i {
                color: #12b4ba !important;
            }
        }
    }

    &__backdrop {
        background-color: rgba(33, 40, 55, 0.5);
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1030;
        transition: 500ms opacity;
        opacity: 0;

        &--open {
            display: block;
            opacity: 1;
        }
    }

    &__button-collapse {
        border: none;
        width: 44px;
        height: 44px;
        background: #ffffff;
        position: absolute;
        top: -22px;
        left: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: 500ms all;
        transform: rotate(180deg);

        &--open {
            transform: rotate(0deg);
            @media (max-width: 992px) {
            right: 0;
        }
        }

        @media (max-width: 992px) {
            right: 0;
        }
    }

}
</style>
